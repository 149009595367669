import React from "react"
import * as styles from "./styles.module.scss"

import { StaticImage } from "gatsby-plugin-image"

const index = () => {
	return (
		<section className={styles.about}>
			<div className="container column">
				<div className={styles.title_about}>
					<h2>Sobre nós</h2>
				</div>
				<div className={styles.about_description}>
					<div className={`${styles.img_description} order_2`}>
						<StaticImage
							src="../../images/sobre3.png"
							alt="sobre_img"
							width={460}
							quality={90}
							formats={["auto", "webp", "avif"]}
							layout="constrained"
							placeholder="tracedSVG"
						/>
					</div>
					<div className={`${styles.text} order_1`}>
						<h2>15 anos de tradição</h2>
						<p>
							A CLIFALI tem servido a população de Taguatinga e
							proximidades por mais de 15 anos, atuando de forma
							diferenciada e especializada nas áreas de psicologia,
							fonoaudiologia, psicopedagogia, neuropediatria e avaliação
							neuropsicológica.
						</p>
					</div>
				</div>

				<div className={styles.about_description}>
					<div className={`${styles.img_description} order_1`}>
						<StaticImage
							src="../../images/sobre1.png"
							alt="sobre_img"
							width={460}
							quality={90}
							formats={["auto", "webp", "avif"]}
							layout="constrained"
							placeholder="tracedSVG"
						/>
					</div>
					<div className={`${styles.text} order_1`}>
						<h2>Nosso espaço</h2>
						<p>
							O espaço físico da clínica conta com ampla estrutura, com
							11 salas climatizadas de atendimento e exames, ampla sala
							de espera ambiente acolhedor e aconchegante. Disponibiliza
							atendimento em horários diferenciados, buscando atrair os
							pacientes que não tem tempo para realizar suas consultas e
							exames durante o horário comercial. Equipe da recepção
							capacitada para acolher os clientes e seus acompanhantes,
							prestando auxílio sempre que necessário.
						</p>
					</div>
				</div>

				<div className={styles.about_description}>
					<div className={`${styles.img_description} order_2`}>
						<StaticImage
							src="../../images/sobre2.png"
							alt="sobre_img"
							width={460}
							quality={90}
							formats={["auto", "webp", "avif"]}
							layout="constrained"
							placeholder="tracedSVG"
						/>
					</div>
					<div className={`${styles.text} order_1`}>
						<h2>Excelência em atendimentos</h2>
						<p>
							A Clifali busca a excelência nos atendimentos oferecidos
							aos seus clientes e conta com profissionais com formação
							contínua em cursos de extensão, aperfeiçoamento e
							pós-graduações, possibilitando aos seus beneficiários um
							tratamento individualizado com empatia e respeito. Por
							acreditar que o conhecimento precisa ser constantemente
							renovado, pois sempre haverá possibilidade para novas
							aprendizagens a Clifali realiza reuniões mensais para
							supervisões, estudos de casos clínicos e troca de
							experiências na atuação profissional. Nossa intenção é
							conservar e aprimorar o bom relacionamento que possuímos
							com nossos clientes e manter o atendimento de excelência
							que oferecemos aos seus beneficiários.
						</p>
					</div>
				</div>

				<div className={styles.about_description}>
					<div className={`${styles.img_description} order_1`}>
						<StaticImage
							src="../../images/sobre4.png"
							alt="sobre_img"
							width={460}
							quality={90}
							formats={["auto", "webp", "avif"]}
							layout="constrained"
							placeholder="tracedSVG"
						/>
					</div>
					<div className={`${styles.text} order_1`}>
						<h2>Atendimento especializado</h2>
						<p>
							Sabemos a importância do atendimento interdisciplinar para
							a evolução do quadro dos pacientes e a CLIFALI prioriza um
							atendimento de alta qualidade, com reuniões dos
							profissionais e estudos de caso de seus pacientes em comum.
						</p>
					</div>
				</div>

				<div className={styles.about_description}>
					<div className={`${styles.img_description} order_2`}>
						<StaticImage
							src="../../images/sobre5.png"
							alt="sobre_img"
							width={460}
							quality={90}
							formats={["auto", "webp", "avif"]}
							layout="constrained"
							placeholder="tracedSVG"
						/>
					</div>
					<div className={`${styles.text} order_1`}>
						<h2>Nossos Serviços</h2>
						<p>
							A CLIFALI oferece os serviços de avaliação, diagnóstico e
							intervenção terapêutica para públicos diversos, como:
							adultos, crianças, idosos, casais e famílias nas diferentes
							áreas da saúde, e atualmente é referência no atendimento
							interdisciplinar com crianças que apresentam Transtorno do
							Espectro do Autismo.
						</p>
					</div>
				</div>
			</div>
		</section>
	)
}

export default index
