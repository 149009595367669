import React from "react"

import Layout from "../components/Layout"
import Seo from "../components/seo"
import SectionAbout from "../components/SectionAbout"

const ClinicaPage = () => (
	<Layout>
		<Seo title="Clínica" />

		<SectionAbout />
	</Layout>
)

export default ClinicaPage
